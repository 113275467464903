import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({ location }) => (
	<Layout>
		<SEO
			location={location}
			title=""
		/>
		<div className="embed-iframe-container view">
			<iframe title="The Master List" className="embed-frame view" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQdqXzj4Em1bVsiDvZnnWm-U0cuPHgwmvHgDXji6kqI2Vqvn2PqXMzvzRHQFqs_8XW_KVsQxceRvVzz/pubhtml"></iframe>
		</div>
	</Layout>
);

export default IndexPage;
